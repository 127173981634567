import { Text } from "@mantine/core";
import { CSSProperties } from "react";

interface RenderTextProps {
  text: string | number;
  isAmount?: boolean;
  color?: string;
  fontWeight?: number;
  style?: CSSProperties;
  component?: any;
}

const RenderText = ({ text, isAmount = false, color = "#444", fontWeight = 500, style = {}, component }: RenderTextProps) => (
  <Text
    size="sm"
    fw={fontWeight}
    c={color}
    ta={isAmount ? "end" : undefined}
    w={isAmount ? undefined : "max-content"}
    style={{ whiteSpace: isAmount ? "nowrap" : undefined, ...style }}
    component={component ? component : undefined}>
    {text}
  </Text>
);

export default RenderText;
