import { Avatar, Flex, Modal, Text } from "@mantine/core";
import { useState } from "react";
import { dateTimeFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PopoverText } from "../popover-text/popover-text";
import { InvoiceAttachmentModalForm } from "../sales-invoice/invoice-attachment-modal-form";
import { ColumnType, RowAction, Table } from "../table";
import RenderText from "../ui/render-text";

type IFileItem = {
  name: string | undefined;
  description: string | undefined;
  url: string | undefined;
  date: string | undefined;
  attachedUser?: string | undefined;
  id: string;
  createdAt: Date | undefined;
};

export function BuyerAttachmentDetailsForm({ values }: { values: any }) {
  const [action, setAction] = useState<any>([]);
  const columns = useHeader({
    onClick: (key, item) => {
      setAction([key, item]);
    },
  });

  return (
    <>
      <Table columns={columns} name="attachments.details" dataSource={values.attachments} />
      <Modal
        opened={action[0] === "details"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="xl"
        centered>
        <InvoiceAttachmentModalForm action={action} setAction={setAction} values={values} setFieldValue={() => {}} />
      </Modal>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (e: string, record: IFileItem) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => <RenderText text={record?.name ?? "-"} />,
  },
  {
    title: "Хавсралт файл",
    sorter: true,
    dataIndex: "url",
    render: (record) =>
      record?.url ? (
        <Text component="a" href={record?.url} size="sm">
          {record?.url?.slice(66, 90)}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Огноо",
    sorter: true,
    dataIndex: "date",
    render: (record) => <RenderText text={record.createdAt ? dateTimeFormat(record.createdAt) : dateTimeFormat(new Date())} />,
  },
  {
    title: "Хавсаргасан",
    sorter: true,
    dataIndex: "attachedUser",
    render: (record) => {
      return (
        <Flex align="center" gap="sm">
          <Avatar src={record?.attachedUser?.avatar || "#"} />
          <RenderText text={renderName(record?.attachedUser)} />
        </Flex>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText>{record.description}</PopoverText>,
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <RowAction
          onClick={(key) => onClick(key, record)}
          extra={{
            details: "дэлгэрэнгүй",
          }}
        />
      );
    },
  },
];
