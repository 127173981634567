import { LoadingOverlay, Paper } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { Layout } from "./components/layout";
import { usePermission } from "./components/permission";
import { onMessageListener, requestForToken } from "./firebase/fcm-service";
import { IAuth } from "./interfaces/IAuth";
import { Access } from "./pages/access";
import { SettlementDetails } from "./pages/account-balance/history-invoiced";
import { InvoiceSettlementPage } from "./pages/account-balance/list";
import { InvoiceDetails } from "./pages/buyer-invoice/invoice-details";
import { InvoicePay } from "./pages/buyer-invoice/invoice-pay";
import { BuyerInvoiceList } from "./pages/buyer-invoice/list";
import { ClosedInvoiceDetails } from "./pages/closed-invoice/details";
import { ClosedInvoiceList } from "./pages/closed-invoice/list";
import { BillingDetails } from "./pages/customer-billing/billing-details";
import { BillingHistory } from "./pages/customer-billing/history-invoiced";
import { CustormerBilling } from "./pages/customer-billing/list";
import { Dashboard } from "./pages/dashboard/dashboard";
import { NotFound } from "./pages/not-found";
import { SalesInvoiceDetails } from "./pages/sales-invoice/details";
import { SalesInvoiceList } from "./pages/sales-invoice/list";
import { SalesInvoiceForms } from "./pages/sales-invoice/new";
import { OurBuyerDetails } from "./pages/sales-invoice/our-buyer";
import { Test } from "./pages/test";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";
import { message } from "./utils/Message";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { accessToken, myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const { hasAccess, accessDenied } = usePermission("INV_DASH");
  const INV_NET_SETT = usePermission("INV_NET_SETT");

  const hasRequestedToken = useRef(false);

  // Firebase
  useEffect(() => {
    if (!hasRequestedToken.current) {
      requestForToken();
      hasRequestedToken.current = true;
    }

    onMessageListener((payload: any) => {
      showNotification({
        id: `notif-${Math.random()}`,
        title: payload?.notification?.title || "New Notification",
        message: payload?.notification?.body || "You have received a new notification.",
        color: "blue",
        autoClose: 5000,
      });
    });
  }, []);

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken) {
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));
          const auth = await AuthApi.me();
          dispatch(authMe(auth));
        }

        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading) return <LoadingOverlay visible={loading} />;
  else
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={hasAccess ? <Dashboard /> : <Paper p={"xs"}>{accessDenied()}</Paper>} />

          {/* Нээлттэй нэхэмжлэх */}
          {myBusinessType === "SUPPLIER" && (
            <>
              <Route path="/sales-invoice" element={<SalesInvoiceList />} />
              <Route path="/sales-invoice/:id" element={<SalesInvoiceForms />} />
              <Route path="/sales-invoice/:id/details" element={<SalesInvoiceDetails />} />
              <Route path="/sales-invoice/:id/our-buyer" element={<OurBuyerDetails />} />
            </>
          )}

          {/* Нээлттэй нэхэмжлэх */}
          {myBusinessType === "BUYER" && (
            <>
              <Route path="/buyer-invoice" element={<BuyerInvoiceList />} />
              <Route path="/buyer-invoice/:id/pay" element={<InvoicePay />} />
              <Route path="/buyer-invoice/:id/:action" element={<InvoiceDetails />} />
            </>
          )}

          <Route path="/closed-invoice" element={<ClosedInvoiceList />} />
          <Route path="/closed-invoice/:id/details" element={<ClosedInvoiceDetails />} />

          <Route path="/settlement" element={<InvoiceSettlementPage />} />
          <Route path="/settlement/:id" element={<SettlementDetails />} />
          <Route path="/settlement/:id/history" element={<BillingHistory />} />

          <Route path="/customer-billing" element={<CustormerBilling />} />
          <Route path="/customer-billing/:id" element={<BillingDetails />} />
          <Route
            path="/customer-billing/:id/history"
            element={INV_NET_SETT.hasAccess ? <BillingHistory /> : <Paper p={"xs"}>{INV_NET_SETT.accessDenied()}</Paper>}
          />
          <Route path="/access" element={<Access />} />
          <Route path="/test" element={<Test />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
}

export default App;
