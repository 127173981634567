import { Avatar, Button, Flex, Input, Modal, Paper, Text } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { dateTimeFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, RowAction, Table } from "../table";
import RenderText from "../ui/render-text";
import { InvoiceAttachmentModalForm } from "./invoice-attachment-modal-form";

type IFileItem = {
  name: string | undefined;
  description: string | undefined;
  url: string | undefined;
  date: string | undefined;
  attachedUser?: string | undefined;
  id: string;
  createdAt: Date | undefined;
};

export function InvoiceAttachmentForm({ values, setFieldValue }: any) {
  const [action, setAction] = useState<any>([]);
  const columns = useHeader({
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue(
          "attachments",
          values.attachments.filter((f: any) => f.id !== item.id),
        );
      } else if (key === "edit") setAction([key, item]);
    },
  });

  return (
    <Paper>
      <Flex direction="column" gap="md">
        <Flex align="center" gap="sm">
          <Input placeholder="Хайх..." w={"100%"} leftSection={<IconSearch size={18} />} />
          <Button onClick={() => setAction(["add"])}>Файл нэмэх</Button>
        </Flex>
        <Table columns={columns} name="attachments" dataSource={values.attachments} />
      </Flex>
      <Modal
        radius="sm"
        opened={action[0] === "add" || action[0] === "edit"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="xl"
        centered>
        <InvoiceAttachmentModalForm action={action} setAction={setAction} values={values} setFieldValue={setFieldValue} />
      </Modal>
    </Paper>
  );
}

const useHeader = ({ onClick }: { onClick: (e: string, record: IFileItem) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => <RenderText text={record?.name ?? "-"} />,
  },
  {
    title: "Хавсралт файл",
    dataIndex: "url",
    render: (record) =>
      record?.url ? (
        <Text component="a" href={record?.url} size="sm">
          {record?.url?.slice(66, 90)}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Огноо",
    sorter: true,
    dataIndex: "date",
    render: (record) => <RenderText text={record.createdAt ? dateTimeFormat(record.createdAt) : dateTimeFormat(new Date())} />,
  },
  {
    title: "Хавсаргасан",
    sorter: true,
    dataIndex: "attachedUser",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={record?.attachedUser?.avatar ?? "#"} />
        <RenderText text={renderName(record?.attachedUser)} />
      </Flex>
    ),
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText>{record.description}</PopoverText>,
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <RowAction
          onClick={(key) => onClick(key, record)}
          extra={{
            edit: "Засах",
            remove: "Устгах",
          }}
        />
      );
    },
  },
];
