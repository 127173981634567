import { Flex, MantineColor, Select, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";

export const SelectFilter = ({
  label,
  value,
  onChange,
  data = [],
  disabled = false,
  iconWidth,
  placeholder,
  onSearchChange,
}: {
  label: string;
  value: string;
  onChange: (e: string | null) => void;
  data: {
    code: string;
    color?: MantineColor;
    name: string;
  }[];
  disabled?: boolean;
  iconWidth?: number;
  placeholder?: string;
  onSearchChange?: (e: string) => void;
}) => {
  return (
    <div>
      <Select
        onSearchChange={onSearchChange}
        disabled={disabled}
        clearable
        value={value}
        onChange={onChange}
        leftSectionWidth={iconWidth || 160}
        searchable
        placeholder={placeholder || "Сонгоно уу"}
        nothingFoundMessage="Хайлт олдсонгүй."
        style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
        data={data.map((item: any) => {
          return {
            value: item.code || "",
            disabled: item?.disabled || false,
            label: item?.name || "",
          };
        })}
        leftSection={
          <Flex w={"calc(100% - 20px)"} align="center" gap="sm">
            <Text size="sm" c="gray.7">
              {label}
            </Text>
            <IconChevronRight size={13} />
          </Flex>
        }
      />
    </div>
  );
};
