import { Select, SelectProps } from "@mantine/core";
import { useField } from ".";

type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

type Props = Omit<SelectProps, "data" | "onChange"> & {
  name: string;
  options: Option[];
  onChange?: (value: string | null) => void;
  onSearchChange?: (value: string) => void;
  noError?: boolean;
  selectItem?: any;
};

export function SelectField({
  name,
  label,
  placeholder,
  noError = false,
  onChange,
  options = [],
  disabled = false,
  clearable = false,
  required = false,
  searchable = false,
  onSearchChange,
  readOnly = false,
  rightSection,
  selectItem,
  ...props
}: Props) {
  const { value, error, onChange: fieldOnChange } = useField(name);

  return (
    <Select
      value={value}
      rightSection={rightSection}
      readOnly={readOnly}
      searchable={searchable}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      onSearchChange={onSearchChange}
      onChange={(selectedValue) => {
        fieldOnChange(selectedValue);
        onChange?.(selectedValue);
      }}
      error={error}
      renderOption={selectItem}
      required={required}
      clearable={clearable}
      data={options}
      {...props}
      styles={{
        error: { display: noError ? "none" : "block" },
        wrapper: { marginBottom: noError && error ? 0 : undefined },
      }}
    />
  );
}
