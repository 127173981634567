import { Alert, Badge, Box, Button, Collapse, Divider, Flex, Grid, Group, Paper, Space, Tabs, Text, TextInput, UnstyledButton } from "@mantine/core";
import { IconCheckbox, IconDeviceFloppy, IconSearch, IconSettings, IconX } from "@tabler/icons-react";
import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { InvoiceApi, NetworkApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { MultiFileUploadField } from "../form/multi-file-upload-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { RegisterTable } from "./register-table";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  receiverBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
  paymentMethod: yup.string().required("Заавал бөглөнө!").nullable(),
  totalAmount: yup.number().required("Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable(),
  trxRefCode: yup.string().required("Заавал бөглөнө!").nullable(),
  invoices: yup
    .array()
    .of(
      yup.object().shape({
        invoiceId: yup.string().required("Заавал бөглөнө!").nullable(),
        amount: yup.number().min(10, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
      }),
    )
    .required("not-empty"),
  files: yup.array().min(1, "Заавал бөглөнө!").of(yup.string().required("Заавал бөглөнө!").nullable()).required("Заавал бөглөнө!"),
});

type IHistoryCreate = {
  receiverBusinessId: string | undefined | null;
  paymentMethod: string | undefined | null;
  totalAmount: number | undefined | null;
  trxRefCode: string | undefined | null;
  invoices:
    | any[]
    | {
        invoiceId: undefined | string;
        amount: number | undefined;
      }[];
  files: any[];
};
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  refCode: string;
  label: string;
  item: string;
}

export function RegisterPayment({ action, setAction, reload }: { action: any; setAction: (e: any) => void; reload: () => void }) {
  const { paymentMethod = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [queryBusiness, setQueryBusiness] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [businessSelectFilter, setBusinessSelectFilter] = useState<any>(undefined);

  const [selected, setSelected] = useState<
    {
      amount: number;
      invoiceId: string;
    }[]
  >([]);
  const { data: businessList = [] } = useSwr(
    `/inv/invoice/history/business_select?&query=${queryBusiness || ""}${JSON.stringify(businessSelectFilter)}`,
    async () => await NetworkApi.business_select({ query: queryBusiness || "", ...businessSelectFilter }),
    {
      fallback: [],
    },
  );

  const [filters, setFilters] = useState({
    receiverBusinessId: undefined,
    partnerQuery: undefined,
    partnerRegNumber: undefined,
  });

  const [data] = useState<IHistoryCreate>({
    receiverBusinessId: "",
    paymentMethod: "BARTER",
    totalAmount: undefined,
    trxRefCode: "",
    invoices: [],
    files: [],
  });

  const onSubmit = async (values: IHistoryCreate) => {
    setLoading(true);
    try {
      await InvoiceApi.historyCreate({
        ...values,
        files: values.files,
        totalAmount: values.totalAmount as number,
        invoices:
          values?.invoices?.map((item: any) => {
            return {
              invoiceId: item?.invoiceId || "",
              amount: item?.amount || "",
            };
          }) || [],
      });

      message.success("Төлбөр амжилттай бүртгэсэн.");

      setAction([]);
      reload();
    } catch (error: any) {
      error.message && message.error(error.message);
    }
    setLoading(false);
  };

  const onSearchBusiness = async (query: string | null) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      setQueryBusiness(query);
    }, 800);
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ refCode, label, item, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            # {refCode}
          </Text>
        </div>
      </Group>
    </div>
  ));

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        console.log("errors: ", errors);
        return (
          <Paper p={"lg"} style={{ overflowY: "auto", height: "100vh", overflowX: "hidden" }}>
            <Tabs value={"1"}>
              <Tabs.List>
                <Tabs.Tab px={"lg"} value="1" leftSection={<IconSettings size={20} />} py={"md"}>
                  Хүлээн авсан төлбөр бүртгэх
                </Tabs.Tab>
                <Tabs.Tab
                  onClick={() => setAction([])}
                  type="button"
                  ml="auto"
                  px={"lg"}
                  value="2"
                  py={"md"}
                  leftSection={<IconX size={20} color="gray" />}></Tabs.Tab>
              </Tabs.List>
            </Tabs>
            <Grid>
              <Grid.Col>
                <Text fw={500} c="gray" mt={"lg"}>
                  Худалдан авагчийг сонгох, хайх
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  selectItem={SelectItem}
                  searchable
                  clearable
                  name="receiverBusinessId"
                  onChange={(e: any) => {
                    setFieldValue("invoices", []);
                    setSelected([]);
                    setFilters({ ...filters, receiverBusinessId: e });
                  }}
                  onSearchChange={(e) => onSearchBusiness(e)}
                  label="Худалдан авагч бизнес сонгох"
                  placeholder="Худалдан авагч бизнес сонгох"
                  options={(businessList ?? []).map((item: any) => {
                    return {
                      value: item.id ?? "",
                      label: item.profileName ?? "",
                      refCode: item?.refCode ?? "",
                    };
                  })}
                  required
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextInput
                  disabled
                  rightSection={<IconSearch size={18} color="gray" />}
                  placeholder="Партнерийн ТТД-аар хайх"
                  label="Партнерийн ТТД-аар хайх"
                  onChange={(e: any) => {
                    setBusinessSelectFilter({ ...businessSelectFilter, partnerRegNumber: e.target.value });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextInput
                  disabled
                  placeholder="Партнерийн нэр, дугаараар хайх"
                  label="Партнерийн нэр, дугаараар хайх"
                  rightSection={<IconSearch size={18} color="gray" />}
                  onChange={(e: any) => {
                    setBusinessSelectFilter({ ...businessSelectFilter, partnerRegNumber: e.target.value });
                  }}
                />
              </Grid.Col>
              <Grid.Col>
                <Text fw={500} c="gray">
                  Хүлээн авсан төлбөрийн мэдээлэл
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  searchable
                  clearable
                  label="Төлбөрийн хэлбэр"
                  placeholder="Төлбөрийн хэлбэр"
                  name="paymentMethod"
                  options={paymentMethod?.map((item: IReference | any) => {
                    return {
                      value: item.code,
                      label: item.name,
                    };
                  })}
                  required
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <NumberCurrencyField name="totalAmount" label="Хүлээн авсан мөнгөн дүн" placeholder="Хүлээн авсан мөнгөн дүн" required />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField name="trxRefCode" label="Гүйлгээний лавлах дугаар:" placeholder="Гүйлгээний лавлах дугаар" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Group align="right" justify="flex-end">
                  <Box>
                    <Divider mt={"xs"} mb={"lg"} color="violet" />
                    <Group pl={"lg"} align="right" w="100%" justify="flex-end">
                      <Box>
                        <Text fw={500} size={"sm"} ta="end">
                          Хүлээн авсан мөнгөн дүн
                        </Text>
                        <Text c="violet" fw={700} size={"lg"} ta="end">
                          {currencyFormat(values.totalAmount || 0)}
                        </Text>
                      </Box>
                      <Box />
                      <Box>
                        <Text fw={500} size={"sm"} ta="end">
                          Бүртгэсэн мөнгөн дүн
                        </Text>
                        <Text c="violet" fw={700} size={"lg"} ta="end">
                          {values?.invoices?.length > 0
                            ? currencyFormat(values?.invoices?.reduce((accumulator: number, currentValue: any) => accumulator + currentValue?.amount || 0, 0))
                            : currencyFormat(0)}
                        </Text>
                      </Box>
                      <Box />
                      <Box>
                        <Text fw={500} size={"sm"} ta="end">
                          Зөрүү
                        </Text>
                        <Text c="violet" fw={700} size={"lg"} ta="end">
                          {currencyFormat(
                            (values.totalAmount || 0) -
                              (values?.invoices?.length > 0
                                ? values?.invoices?.reduce((accumulator: number, currentValue: any) => accumulator + currentValue?.amount || 0, 0)
                                : 0),
                          )}
                        </Text>
                      </Box>
                      <Box />
                    </Group>
                    <Divider mt={"lg"} color="violet" />
                  </Box>
                </Group>
              </Grid.Col>

              <Grid.Col>
                <Text fw={500} c="gray">
                  Холбогдох баримтууд бүртгэх
                </Text>
                <Space h={"lg"} />

                <MultiFileUploadField name="files" label="Хавсралт оруулах" required />
              </Grid.Col>

              <Grid.Col>
                <Text fw={500} c="gray" my={""}>
                  Сонгосон худалдан авагчийн төлбөрийн үлдэгдэлтэй нэхэмжлэх жагсаалт
                </Text>
                <Paper>
                  <Collapse in={values?.receiverBusinessId}>
                    <Grid>
                      <Grid.Col>
                        <Paper w={"100%"} p={"xs"} withBorder>
                          <Flex
                            align="center"
                            gap={8}
                            style={{
                              minHeight: "20px",
                            }}
                            wrap={"wrap"}>
                            <IconCheckbox size={"1.2rem"} color="orange" />
                            {values?.invoices?.length > 0 ? (
                              values?.invoices?.map((item: any, index: number) => {
                                return (
                                  <Badge key={index} variant="light">
                                    <Flex align="center" gap={4}>
                                      <Text fw={500} component="span" size="sm">
                                        {item?.refCode}:
                                      </Text>
                                      <Text size="sm" fw={500} component="span" c={!item?.amount ? "red" : "black"}>
                                        {currencyFormat(item?.amount || 0)}
                                      </Text>
                                      <UnstyledButton
                                        onClick={() => {
                                          setSelected(selected.filter((item2: any) => item2.invoiceId !== item.invoiceId));
                                        }}
                                        mt={2}>
                                        <IconX color="gray" size="13px" />
                                      </UnstyledButton>
                                    </Flex>
                                  </Badge>
                                );
                              })
                            ) : (
                              <Text size={"xs"} c="gray">
                                Сонгогдсон нэхэмжлэх байхгүй
                              </Text>
                            )}
                          </Flex>
                        </Paper>
                        <Space mt={"xs"} />
                        <RegisterTable selected={selected} setSelected={setSelected} filters={filters} values={values} setFieldValue={setFieldValue} />

                        <Collapse in={errors.invoices === "not-empty" || false}>
                          <Alert color="red" title="Заавал бөглөнө!">
                            Та төлөлт бүртгэх нэхэмжлэх ээ сонгоно уу.
                          </Alert>
                        </Collapse>
                      </Grid.Col>
                    </Grid>
                  </Collapse>
                  <Collapse in={!values?.receiverBusinessId}>
                    <Alert title="Санамж!">Худалдан авагч бизнес сонгоно уу.</Alert>
                  </Collapse>
                </Paper>
              </Grid.Col>

              <Grid.Col>
                <Flex gap={8} justify={"space-between"}>
                  <Button type="button" onClick={() => setAction([])} variant="default">
                    Буцах
                  </Button>
                  <Button disabled={!values.receiverBusinessId} loading={loading} type="submit" color="brand" leftSection={<IconDeviceFloppy />}>
                    Төлбөр бүртгэх
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </Paper>
        );
      }}
    </Form>
  );
}
