import { Box, Checkbox, NumberInput } from "@mantine/core";
import { IconCurrencyTugrik } from "@tabler/icons-react";
import { useEffect } from "react";
import { InvoiceApi } from "../../apis";
import { IItem } from "../../interfaces/IItem";
import { currencyFormat } from "../../utils";
import { dateFormat, dateTimeFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function RegisterTable({
  values,
  setFieldValue,
  filters,
  selected,
  setSelected,
}: {
  values: any;
  setFieldValue: (e1: string, e2: any) => void;
  filters: any;
  selected: any;
  setSelected: any;
}) {
  const columns = useHeader({
    values,
    setFieldValue,
    selected,
    setSelected,
    onClick: (key, record) => {
      if (key === "add") {
        setSelected([
          ...selected,
          {
            amount: 0,
            invoiceId: record.id,
            refCode: record?.refCode,
          },
        ]);
      } else if (key === "remove") {
        setSelected(selected.filter((item: any) => item.invoiceId !== record.id));
      } else if (key === "update") {
        setSelected(selected.map((item: any) => (item.invoiceId === record.id ? { ...item, amount: record.amount } : item)));
      }
    },
  });

  useEffect(() => {
    setFieldValue("invoices", selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return <Table name={`invoices.history.list_${filters}`} columns={columns} filters={filters} loadData={(data) => InvoiceApi.invoice_list(data!)} />;
}

const useHeader = ({
  onClick,
  values,
  setFieldValue,
  selected,
  setSelected,
}: {
  onClick: (key: string, record: IItem | any) => void;
  values: any;
  setFieldValue: (e1: string, e2: any) => void;
  selected: any;
  setSelected: any;
}): ColumnType<IItem | any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Сонгох",
    align: "center",
    render: (record) => {
      return (
        <Checkbox
          color="brand"
          checked={selected.some((item: any) => item?.invoiceId === record.id)}
          value={record.id}
          onChange={(e) => {
            if (selected.some((item: any) => item?.invoiceId === record.id)) onClick("remove", record);
            else onClick("add", record);
          }}
        />
      );
    },
  },
  {
    title: "Нэхэмжлэх №",
    dataIndex: "refCode",
    render: (record) => {
      return record?.refCode === null ? (
        "-"
      ) : (
        <Box>
          <RenderText text={`#${record?.refCode}`} color="brand" />
          <RenderText text={dateFormat(record?.createdAt)} />
        </Box>
      );
    },
  },
  {
    title: "Худалдан авагч",
    dataIndex: "category",
    render: (record) => (
      <Box>
        <RenderText text={record?.receiverBusiness?.partner?.businessName || "-"} />
        <RenderText text={`#${record?.receiverBusiness?.partner?.refCode || "-"}`} />
      </Box>
    ),
  },
  {
    title: "Бизнесийн нэр",
    dataIndex: "itemStatus",
    render: (record) => (
      <Box>
        <RenderText text={record?.receiverBusiness?.profileName || "-"} />
        <RenderText text={`#${record?.receiverBusiness?.refCode || "-"}`} color="brand" />
      </Box>
    ),
  },
  {
    title: "Бүртгэх төлбөр",
    dataIndex: "selected",
    render: (record) => (
      <Box w={"200px"}>
        {selected.some((item: any) => item.invoiceId === record.id) ? (
          <NumberInput
            min={0}
            disabled={!selected.some((item: any) => item.invoiceId === record.id)}
            rightSection
            leftSection={<IconCurrencyTugrik size={18} stroke="1.5" color="gray" opacity={0.8} />}
            placeholder="0.00"
            error={!selected.find((item: any) => item.invoiceId === record.id)?.amount}
            value={selected.find((item: any) => item.invoiceId === record.id)?.amount || undefined}
            onChange={(e: any) => {
              onClick("update", { ...record, amount: e });
            }}
          />
        ) : (
          "-"
        )}
      </Box>
    ),
  },
  {
    title: "Үлдэх тооцоо",
    dataIndex: "amountToPay",
    render: (record) => (
      <RenderText
        text={currencyFormat((record?.amountToPay || 0) - (selected.find((item: any) => item?.invoiceId === record?.id)?.amount || 0))}
        isAmount
        color={
          (record?.amountToPay || 0) - (selected.find((item: any) => item?.invoiceId === record?.id)?.amount || 0) === 0
            ? "green.5"
            : (record?.amountToPay || 0) - (selected.find((item: any) => item?.invoiceId === record?.id)?.amount || 0) > 0
            ? "#444"
            : "red.5"
        }
      />
    ),
  },
  {
    title: "Үлдэгдэл төлбөр",
    dataIndex: "amountToPay",
    render: (record) => <RenderText text={currencyFormat(record?.amountToPay || 0)} isAmount />,
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    dataIndex: "paymentDate",
    render: (record) => <RenderText text={record?.paymentDate ? dateTimeFormat(record?.paymentDate) : "-"} />,
  },
  {
    title: "Үлдсэн хугацаа",
    dataIndex: "remainingDays",
    render: (record) => <RenderText text={`${record?.remainingDays} хоног`} />,
  },

  {
    title: "Илгээсэн огноо",
    dataIndex: "sentDate",
    render: (record) => <RenderText text={record?.sentDate ? dateTimeFormat(record?.sentDate) : "-"} />,
  },
];
