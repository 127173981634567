import { ActionIcon, Anchor, Avatar, Box, Button, Flex, Group, Image, Modal, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconFileText, IconFileTypePdf, IconPhoto, IconPrinter, IconReload } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EbarimtApi, InvoiceApi } from "../../apis";
import ebarimt from "../../assets/ebarimt_logo.png";
import { PageLayout } from "../../components/layout";
import { CommingSoon } from "../../components/layout/comming-soon";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/Message";
import { renderName } from "../../utils/render-name";

export const ClosedInvoiceList = () => {
  const breadcrumbs = useBreadcrumb();
  const INV_CLOSED = usePermission("INV_CLOSED");

  const { invoiceStatus = [], invoicePaymentStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const [load, setLoad] = useState<string[]>([]);
  const [eload, setEload] = useState<string[]>([]);
  const [eReturn, setEReturn] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [dataChanged, setDataChanged] = useState(false);

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState({
    query: "",
    isReceived: false,
  });

  const pdf = async (id: string) => {
    let res = await InvoiceApi.pdf(id);
    setLoad([...load.filter((item: string) => item !== id)]);
    window.open(res);
  };

  const receipt = async (record: any) => {
    if (record.ebarimtRegistered) {
      setOpen(true);
      setPdfUrl(record.barimtUrl);
      setEload([""]);
    } else {
      try {
        const res = await EbarimtApi.ebarimt(record.id);
        setEload([...eload.filter((item: string) => item !== record.id)]);

        if (res && res.status === "SUCCESS") {
          setOpen(true);
          setPdfUrl(res.url);
          setEload([""]);
        }
      } catch (error: any) {
        setEload([""]);
        setOpen(false);
        message.error(error?.message?.message || error?.message);
      }
    }
  };

  const handleReturnClick = (record: any) => {
    modals.openConfirmModal({
      title: "Баримт буцаах",
      children: <Text size="sm" ta="center">{`Таны ${record.refCode} баримтыг буцаах гэж байна та итгэлтэй байна уу?`}</Text>,
      labels: { confirm: "Буцаах", cancel: "Болих" },
      centered: true,
      onClose: () => setEReturn([""]),
      onCancel: () => setEReturn([""]),
      onConfirm: async () => {
        try {
          const res = await EbarimtApi.ebarimtReturn(record.id);
          if (res) {
            message.success("Баримт амжилттай буцаалаа");
            setEReturn([""]);
            setDataChanged((prev) => !prev);
          }
        } catch (error: any) {
          message.error(error?.message?.message || error?.message);
          setEReturn([""]);
        }
      },
    });
  };

  const columns = useHeader({
    myBusinessType,
    invoicePaymentStatus: invoicePaymentStatus,
    invoiceStatus: invoiceStatus,
    load,
    eload,
    eReturn,
    onClick: (key, record) => {
      if (key === "edit") navigate(`/closed-invoice/${record?.id}`);
      if (key === "details") navigate(`/closed-invoice/${record?.id}/details`);
      if (key === "pdf") {
        setLoad([...load, `${record.id}`]);
        pdf(`${record.id}`);
      }
      if (key === "receipt") {
        setEload([...eload, record.id ?? ""]);
        receipt(record ?? "");
      }
      if (key === "return") {
        setEReturn([...eReturn, record.id ?? ""]);
        handleReturnClick(record);
      }
    },
  });

  return (
    <PageLayout
      title="Хаагдсан нэхэмжлэх"
      subTitle="Төлөлт хийгдсэн буюу төлж дууссан нэхэмжлэлүүд"
      breadcrumb={breadcrumbs}
      extra={[
        <CommingSoon visible={true} position="top" key={1} width={200}>
          <Button variant="light" px={"xs"}>
            <IconPrinter />
          </Button>
        </CommingSoon>,
      ]}>
      <div>
        {INV_CLOSED.hasAccess ? (
          <>
            <Table
              name="sales.invoice.list.sent.CLOSED.supplier"
              columns={columns}
              filters={{ ...filters, isReceived: myBusinessType === "BUYER" }}
              loadData={(data) => InvoiceApi.close(data!)}
              key={dataChanged ? "data-changed" : "data"}
            />
            <Modal
              opened={open}
              onClose={() => {
                setOpen(false);
                setPdfUrl("");
                setEload([""]);
              }}
              title="Төлбөрийн баримт"
              size="90%">
              {pdfUrl && <iframe src={pdfUrl} width="100%" height="700px" style={{ border: "none" }} title="ebarimt" />}
            </Modal>
          </>
        ) : (
          INV_CLOSED.accessDenied()
        )}
      </div>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  myBusinessType = "",
  invoicePaymentStatus = [],
  invoiceStatus = [],
  load = [],
  eload = [],
  eReturn = [],
}: {
  onClick: (key: string, record: IItem) => void;
  myBusinessType: string;
  invoicePaymentStatus: IReference[];
  invoiceStatus: IReference[];
  load: string[];
  eload: string[];
  eReturn: string[];
}): ColumnType<IItem | any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex align="center" gap="sm">
          {record.invoiceStatus !== "DRAFT" && "edit" && (
            <Tooltip label="Дэлгэрэнүй">
              <ActionIcon onClick={() => onClick("details", record)} color="" variant="light">
                <IconFileText />
              </ActionIcon>
            </Tooltip>
          )}
          {record.invoiceStatus !== "DRAFT" && (
            <Tooltip label="PDF">
              <ActionIcon loading={load.some((item: string) => item === record.id)} onClick={() => onClick("pdf", record)} variant="light" color="indigo">
                <IconFileTypePdf />
              </ActionIcon>
            </Tooltip>
          )}
          {record?.invoiceStatus === "CLOSED" && (
            <Tooltip label={record?.ebarimtRegistered ? "И-Баримт харах" : "И-Баримт үүсгэх"}>
              <ActionIcon variant="light" loading={eload.some((item: string) => item === record.id)} onClick={() => onClick("receipt", record)}>
                <Image src={ebarimt} alt="" w={24} h={24} style={{ objectFit: "cover" }} />
              </ActionIcon>
            </Tooltip>
          )}
          {record?.ebarimtRegistered && record?.invoiceStatus === "CLOSED" && (
            <Tooltip label={"И-Баримт буцаах"}>
              <ActionIcon variant="light" loading={eReturn.some((item: string) => item === record.id)} onClick={() => onClick("return", record)}>
                <IconReload />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Нэхэмжлэх дугаар",
    dataIndex: "refCode",
    render: (record) => {
      return record?.refCode === null ? "-" : <Text size="sm">{record?.refCode}</Text>;
    },
  },
  {
    title: myBusinessType === "BUYER" ? "Нийлүүлэгч бизнес" : "Худалдан авагч бизнес",
    dataIndex: "category",
    render: (record) =>
      myBusinessType === "BUYER" ? (
        <Flex w="max-content" gap={"xs"} align={"center"}>
          <Avatar src={record?.senderBusiness?.logo} size={32} style={{ borderRadius: 5 }}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm">{record?.senderBusiness?.profileName || "-"}</Text>
            <Anchor size="sm">#{record?.senderBusiness?.refCode || "-"}</Anchor>
          </div>
        </Flex>
      ) : (
        <Flex w="max-content" gap={"xs"} align={"center"}>
          <Avatar src={record?.receiverBusiness?.logo} size={32} style={{ borderRadius: 5 }}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm" c="brand">
              #{record?.receiverBusiness?.refCode || "-"}
            </Text>
            <Text c="dimmed" size="sm" w="max-content">
              {record?.receiverBusiness?.profileName || "-"}
            </Text>
          </div>
        </Flex>
      ),
  },
  {
    title: "Партнер нэр",
    dataIndex: "itemStatus",
    render: (record) =>
      myBusinessType === "BUYER" ? (
        <Group style={{ flex: 1 }}>
          <div>
            <Text size="sm" w="max-content">
              {record?.senderBusiness?.partner?.businessName || "-"}
            </Text>
            <Anchor size="sm" w="max-content">
              #{record?.senderBusiness?.partner?.refCode || "-"}
            </Anchor>
          </div>
        </Group>
      ) : (
        <Group style={{ flex: 1 }}>
          <div>
            <Text size="sm" w="max-content">
              {record?.receiverBusiness?.partner?.businessName || "-"}
            </Text>
            <Anchor size="sm" w="max-content">
              #{record?.receiverBusiness?.partner?.refCode || "-"}
            </Anchor>
          </div>
        </Group>
      ),
  },
  {
    title: "Баталсан дүн",
    dataIndex: "confirmedAmount",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="orange.5" size="sm">
        {currencyFormat(record?.confirmedAmount || 0)}
      </Text>
    ),
  },
  {
    title: "Төлсөн дүн",
    dataIndex: "paidAmount",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="green.5" size="sm">
        {currencyFormat(record?.paidAmount || 0)}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    dataIndex: "amountToPay",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="red.5" size="sm">
        {currencyFormat(record?.amountToPay || 0)}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн статус",
    dataIndex: "paymentStatus",
    render: (record) => {
      return (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <div>
            {Tag(
              invoicePaymentStatus.find((item: IReference) => item.code === record.paymentStatus)?.color || "",
              invoicePaymentStatus.find((item: IReference) => item.code === record.paymentStatus)?.name || record.paymentStatus,
            )}
          </div>
        </Box>
      );
    },
  },
  {
    title: "Баталсан огноо",
    dataIndex: "confirmedDate",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.confirmedDate ? dateTimeFormat(record?.confirmedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    dataIndex: "paymentDate",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.paymentDate ? dateTimeFormat(record?.paymentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Хаагдсан огноо",
    dataIndex: "paidDate",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.paidDate ? dateTimeFormat(record?.paidDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    dataIndex: "invoiceStatus",
    render: (record) => {
      return (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <div>
            {Tag(
              invoiceStatus.find((item: IReference) => item.code === record.invoiceStatus)?.color || "",
              invoiceStatus.find((item: IReference) => item.code === record.invoiceStatus)?.name || record.invoiceStatus,
            )}
          </div>
        </Box>
      );
    },
  },
  {
    title: "Баталсан ажилтан",
    dataIndex: "confirmedUser",
    render: (record) => {
      if (record?.confirmedUser)
        return (
          <Flex gap={10} align="center" w="max-content">
            <Avatar radius="sm" component="a" src={record?.confirmedUser?.avatar} style={{ borderRadius: "100%" }} />
            <Text tt={"capitalize"} size="sm">
              {renderName(record?.confirmedUser)}
            </Text>
          </Flex>
        );
      return "-";
    },
  },
  {
    title: "Илгээсэн огноо",
    dataIndex: "sentDate",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.sentDate ? dateTimeFormat(record.sentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Илгээсэн ажилтан",
    dataIndex: "senderUser",
    render: (record) => (
      <Flex gap={10} align="center" w="max-content">
        <Avatar radius="sm" src={record?.senderUser?.avatar} size={32} style={{ borderRadius: "100%" }} />
        <Text tt={"capitalize"} size="sm">
          {renderName(record?.senderUser)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Борлуулалтын захиалга",
    dataIndex: "salesOrder.refCode",
    render: (record) => {
      return <Text size="sm">{record?.orderSalesCode || "-"}</Text>;
    },
  },
  {
    title: "Худалдан авалтын захиалга",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return <Text size="sm">{record?.orderPurchaseCode || "-"}</Text>;
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Хаагдсан нэхэмжлэх",
  },
];
